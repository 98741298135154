.bgDiv {
  min-width: 100vw;
  min-height: 100vh;
  background-image: url("~/public/img/bg.jpg");
  padding-top: 64px;
  background-size: cover;
  background-position: center;
}
.registerDiv {
  padding: 64px 0px;
  background-color: white;
  border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
