div.tox-tinymce {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.tinyLabel {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    transform: translate(6px, 9px);
    z-index: 3;
    position: relative;
    background-color: white;
    display: inline-block;
    padding: 0px 6px;
}

.representationDiv{
    display: flex;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-wrap: nowrap;
    overflow: auto;
}
.representationDiv > img.selected{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
        rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    background-color: rgba(0, 0, 0, 0.01);
}
.controls{
    display: inline-flex;
    flex-wrap: wrap;
    background-color: white;
    border: 1px solid rgb(232, 234, 238);
    border-radius: 3px;
    margin: 0 16px;
}

.controls .MuiToggleButton-root{
    padding: 0 7px;
}
.toggleStyleImg{
    height: 38px;
    margin: 0 2.5px;
}

.controlsMenu>div>ul{
    padding-top: 0;
    padding-bottom: 0;
}
.controlsDiv{
    padding: 12px 0;
    background-color: rgba(246, 247, 248, 0.5);
    border: 1px solid rgb(232, 234, 238);
    border-left: 0;
    border-right: 0;
}

.representationPaper{
    margin: 16px;
    padding: 16px;
    position: relative;
}
.letterDiv{
    display: flex;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-wrap: nowrap;
    overflow: auto;
}
.representationIcons{
    position: absolute;
    top:0;
    right: 0;
}